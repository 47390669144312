import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  createRoutesFromElements,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { PersistGate } from 'redux-persist/integration/react';
// Import semantic less to integrate custom less variables
// eslint-disable-next-line import/no-extraneous-dependencies
import 'semantic-ui-less/semantic.less';

import configureStore from 'utils/store';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import 'moment/locale/en-gb';
import 'moment/locale/fr';

import baseRoutes from 'components/router/routes';
import BeatingLoader from 'components/ui/BeatingLoader';

import config from 'config';

import GlobalStyle from 'assets/style/GlobalStyle';

let sentryCreateBrowserRouter = createBrowserRouter;

if (process.env.NODE_ENV === 'production' || config.FORCE_SENTRY) {
  sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);
  Sentry.init({
    dsn: config.sentry.dsn,
    release: process.env.npm_package_version,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    debug: false,
  });
}

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(baseRoutes())
);

// This is in an async function because we need to delay app rendering
// until the store is rehydrated (done in configureStore)
function init() {
  const { store, persistor } = configureStore();
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={<BeatingLoader absolute />} persistor={persistor}>
        <I18nProvider i18n={i18n}>
          <GlobalStyle />
          <RouterProvider router={router} />
        </I18nProvider>
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  );
}

init();
