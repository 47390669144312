import { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

const withPolling =
  (pollingAction, duration = 2000) =>
  (BaseComponent) => {
    const Wrapper = () => {
      class WrapperComponent extends Component {
        componentDidMount() {
          // eslint-disable-next-line react/destructuring-assignment
          this.props.pollingAction();
          this.dataPolling = setInterval(() => {
            // eslint-disable-next-line react/destructuring-assignment
            this.props.pollingAction();
          }, duration);
        }

        componentWillUnmount() {
          clearInterval(this.dataPolling);
        }

        render() {
          return <BaseComponent {...this.props} />;
        }
      }
      WrapperComponent.propTypes = { pollingAction: PropTypes.func.isRequired };
      return WrapperComponent;
    };
    const mapStateToProps = () => ({});
    const mapDispatchToProps = { pollingAction };
    return connect(mapStateToProps, mapDispatchToProps)(Wrapper());
  };

export default withPolling;
