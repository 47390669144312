import { getReviewChunks } from 'actions/search';

const getAnalysisSearchDataToExport =
  ({ nChunks }) =>
  async (dispatch, getState) => {
    const { search, view } = getState();
    const viewFacetId = view.viewFacet?.id;
    const { filters } = search.viewFacetSearch[viewFacetId];
    const { data } = await getReviewChunks(viewFacetId, filters, {
      page: 1,
      pageSize: nChunks,
      withStatistics: false,
      withConcepts: true,
      withPolarity: true,
    });
    return data?.data || [];
  };

export default getAnalysisSearchDataToExport;
