import { msg } from '@lingui/macro';
import { omit } from 'lodash';

import { createLoadingSelector } from 'reducers/ui';
import { questionSelectorFactory } from 'selectors/survey';

import { api } from './utils';

export const END_SCREEN_ILLUSTRATION_OPTIONS = [
  { label: msg({ id: 'party-popper' }), value: 'popper' },
  { label: msg({ id: 'raised-hands' }), value: 'raised_hands' },
];

export const actionTypes = {
  ADD_QUESTION_REQUEST: 'ADD_QUESTION_REQUEST',
  ADD_QUESTION_FAILURE: 'ADD_QUESTION_FAILURE',
  ADD_QUESTION_SUCCESS: 'ADD_QUESTION_SUCCESS',
  DELETE_QUESTION: 'DELETE_QUESTION',
  REORDER_QUESTIONS_DRAG_AND_DROP: 'REORDER_QUESTIONS_DRAG_AND_DROP',
  RESET_QUESTION_TYPE: 'RESET_QUESTION_TYPE',
  UPDATE_QUESTION_DESCRIPTION: 'UPDATE_QUESTION_DESCRIPTION',
  UPDATE_QUESTION_TITLE: 'UPDATE_QUESTION_TITLE',
  UPDATE_OPTION_CONFIGURATION: 'UPDATE_OPTION_CONFIGURATION',
  DELETE_OPTION: 'DELETE_OPTION',
  ADD_OPTION_REQUEST: 'ADD_OPTION_REQUEST',
  ADD_OPTION_FAILURE: 'ADD_OPTION_FAILURE',
  ADD_OPTION_SUCCESS: 'ADD_OPTION_SUCCESS',
  REORDER_OPTIONS_DRAG_AND_DROP: 'REORDER_OPTIONS_DRAG_AND_DROP',
  UPDATE_QUESTION_SETTING: 'UPDATE_QUESTION_SETTING',
  DUPLICATE_QUESTION_REQUEST: 'DUPLICATE_QUESTION_REQUEST',
  DUPLICATE_QUESTION_FAILURE: 'DUPLICATE_QUESTION_FAILURE',
  DUPLICATE_QUESTION_SUCCESS: 'DUPLICATE_QUESTION_SUCCESS',
  ADD_LOGIC_RULE: 'ADD_LOGIC_RULE',
  ADD_LOGIC_CONDITION: 'ADD_LOGIC_CONDITION',
  UPDATE_LOGIC_CONDITION: 'UPDATE_LOGIC_CONDITION',
  DELETE_LOGIC_CONDITION: 'DELETE_LOGIC_CONDITION',
  UPDATE_HOME_SCREEN_ELEMENT: 'UPDATE_HOME_SCREEN_ELEMENT',
  UPDATE_END_SCREEN_ELEMENT: 'UPDATE_END_SCREEN_ELEMENT',
  UPDATE_END_SCREEN_ILLUSTRATION: 'UPDATE_END_SCREEN_ILLUSTRATION',
  UPDATE_CONFIDENTIALITY_ELEMENT: 'UPDATE_CONFIDENTIALITY_ELEMENT',
  UPDATE_THEME_ELEMENT: 'UPDATE_THEME_ELEMENT',
  TOGGLE_DISPLAY_HOME_SCREEN_ELEMENT: 'TOGGLE_DISPLAY_HOME_SCREEN_ELEMENT',
  UPDATE_LOGO_URL: 'UPDATE_LOGO_URL',
};

export const reorderQuestionsDragAndDrop = (
  campaignId,
  reorderedQuestions
) => ({
  type: actionTypes.REORDER_QUESTIONS_DRAG_AND_DROP,
  campaignId,
  questions: reorderedQuestions,
});

export const addQuestion =
  (campaignId, questionType) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.ADD_QUESTION_REQUEST,
    });
    const { versionToken } =
      getState().campaign.customization?.[campaignId] || {};
    let response;
    try {
      response = await api.post(
        `/campaign/${campaignId}/preview/add-question/v1?version_token=${versionToken}`,
        {
          type: questionType,
        }
      );
    } catch (error) {
      return dispatch({
        type: actionTypes.ADD_QUESTION_FAILURE,
        questionType,
        versionConflict: error.response?.status === 409,
      });
    }

    dispatch({
      type: actionTypes.ADD_QUESTION_SUCCESS,
      campaignId,
      question: response.data.question,
      versionToken: response.data.version_token,
    });
    return Promise.resolve(response.data.id);
  };

export const duplicateQuestion =
  (campaignId, questionId) => async (dispatch, getState) => {
    const question = omit(
      questionSelectorFactory(campaignId, questionId, true)(getState()),
      ['id']
    );
    const newQuestionIndex = question.index + 1;
    delete question.index;

    if (question.options) {
      question.options = question.options.map((option) =>
        omit(option, ['id', 'index'])
      );
    }
    dispatch({
      type: actionTypes.DUPLICATE_QUESTION_REQUEST,
    });
    const { versionToken } = getState().campaign.customization[campaignId];
    let response;
    try {
      response = await api.post(
        `/campaign/${campaignId}/preview/add-question/v1?version_token=${versionToken}`,
        question
      );
    } catch (error) {
      return dispatch({
        type: actionTypes.DUPLICATE_QUESTION_FAILURE,
        versionConflict: error.response?.status === 409,
      });
    }

    dispatch({
      type: actionTypes.DUPLICATE_QUESTION_SUCCESS,
      campaignId,
      // Index starts at 1 but position starts at 0
      position: newQuestionIndex - 1,
      question: response.data.question,
      versionToken: response.data.version_token,
    });
    return Promise.resolve(response.data.id);
  };

export const deleteQuestion = (campaignId, questionId) => ({
  campaignId,
  questionId,
  type: actionTypes.DELETE_QUESTION,
});

export const setQuestionType =
  (campaignId, questionId, questionType, clearConditions = false) =>
  (dispatch, getState) => {
    if (!questionId) {
      dispatch(addQuestion(campaignId, questionType));
    } else {
      dispatch({
        type: actionTypes.RESET_QUESTION_TYPE,
        campaignId,
        questionId,
        questionType,
        clearConditions,
      });
    }
    return Promise.resolve();
  };

export const updateQuestionTitle = (campaignId, questionId, title) => ({
  type: actionTypes.UPDATE_QUESTION_TITLE,
  campaignId,
  questionId,
  title,
});

export const updateQuestionDescription = (
  campaignId,
  questionId,
  description
) => ({
  type: actionTypes.UPDATE_QUESTION_DESCRIPTION,
  campaignId,
  questionId,
  description,
});

export const updateAnswerText = (campaignId, questionId, optionId, text) => ({
  type: actionTypes.UPDATE_OPTION_CONFIGURATION,
  campaignId,
  questionId,
  optionId,
  field: 'display_name',
  value: text,
});

export const updateAnswerScore = (campaignId, questionId, optionId, score) => ({
  type: actionTypes.UPDATE_OPTION_CONFIGURATION,
  campaignId,
  questionId,
  optionId,
  field: 'score',
  value: score,
});

export const deleteAnswer = (campaignId, questionId, optionId) => ({
  type: actionTypes.DELETE_OPTION,
  campaignId,
  questionId,
  optionId,
});

export const addQuestionOption =
  (campaignId, questionId) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.ADD_OPTION_REQUEST,
    });
    let response;
    const { versionToken } = getState().campaign.customization[campaignId];
    try {
      response = await api.post(
        `/campaign/${campaignId}/preview/question/${questionId}/add-option/v1?version_token=${versionToken}`
      );
    } catch (error) {
      return dispatch({
        type: actionTypes.ADD_OPTION_FAILURE,
        versionConflict: error.response?.status === 409,
      });
    }
    return dispatch({
      type: actionTypes.ADD_OPTION_SUCCESS,
      campaignId,
      questionId,
      versionToken: response.data.version_token,
      option: response.data.option,
    });
  };

export const reorderAnswers = (campaignId, questionId, newAnswersItems) => ({
  type: actionTypes.REORDER_OPTIONS_DRAG_AND_DROP,
  campaignId,
  questionId,
  options: newAnswersItems,
});

export const updateQuestionSetting = (campaignId, questionId, key, value) => ({
  type: actionTypes.UPDATE_QUESTION_SETTING,
  campaignId,
  questionId,
  key,
  value,
});

export const addLogicRule = (campaignId, questionId) => ({
  type: actionTypes.ADD_LOGIC_RULE,
  campaignId,
  questionId,
});

export const addLogicCondition = (campaignId, questionId) => ({
  type: actionTypes.ADD_LOGIC_CONDITION,
  campaignId,
  questionId,
});

export const activateQuestionScoring =
  (campaignId, questionId, value) => (dispatch, getState) => {
    dispatch(
      updateQuestionSetting(campaignId, questionId, 'with_scoring', value)
    );
    if (value) {
      const { options } = questionSelectorFactory(
        campaignId,
        questionId,
        true
      )(getState());
      options.forEach((option) => {
        if (!option.score) {
          dispatch(updateAnswerScore(campaignId, questionId, option.id, 1));
        }
      });
    }
  };

export const addDisplayCondition = (
  campaignId,
  questionId,
  conditionQuestionId,
  conditionIndex,
  conditionValues
) =>
  conditionIndex == null
    ? {
        type: actionTypes.ADD_LOGIC_CONDITION,
        campaignId,
        questionId,
        // conditionIndex,
        conditionQuestionId,
        conditionValues,
      }
    : {
        type: actionTypes.UPDATE_LOGIC_CONDITION,
        campaignId,
        questionId,
        conditionIndex,
        conditionQuestionId,
        conditionValues,
      };

export const deleteLogicCondition = (
  campaignId,
  questionId,
  conditionIndex
) => ({
  type: actionTypes.DELETE_LOGIC_CONDITION,
  campaignId,
  questionId,
  conditionIndex,
});

export const toggleDisplayHomeScreenElement = (campaignId, value) => ({
  type: actionTypes.TOGGLE_DISPLAY_HOME_SCREEN_ELEMENT,
  campaignId,
  value,
});

export const updateHomeScreenElement = (campaignId, key, value) => ({
  type: actionTypes.UPDATE_HOME_SCREEN_ELEMENT,
  campaignId,
  key,
  value,
});

export const updateEndScreenElement = (campaignId, key, value) => ({
  type: actionTypes.UPDATE_END_SCREEN_ELEMENT,
  campaignId,
  key,
  value,
});

export const updateThemeElement = (campaignId, key, value) => ({
  type: actionTypes.UPDATE_THEME_ELEMENT,
  campaignId,
  key,
  value,
});

export const updateEndScreenIllustrationOption = (campaignId, value) => {
  let newValue = value;
  if (value === true) {
    // Toggled, set default value using first option
    newValue = END_SCREEN_ILLUSTRATION_OPTIONS[0].value;
  } else if (value === false) {
    // Untoggled, remove value
    newValue = null;
  }
  return {
    type: actionTypes.UPDATE_END_SCREEN_ELEMENT,
    campaignId,
    key: 'illustration',
    value: newValue,
  };
};

export const updateConfidentialityElement = (campaignId, key, value) => ({
  type: actionTypes.UPDATE_CONFIDENTIALITY_ELEMENT,
  campaignId,
  key,
  value,
});

export const updateLogoUrl = (campaignId, logoUrl) => ({
  type: actionTypes.UPDATE_LOGO_URL,
  campaignId,
  logoUrl,
});

export const surveyAddOptionLoadingSelector = createLoadingSelector([
  actionTypes.ADD_OPTION_REQUEST,
]);
