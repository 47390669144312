import { api } from 'actions/utils';

export const actionTypes = {
  FETCH_SOURCES_REQUEST: 'FETCH_SOURCES_REQUEST',
  FETCH_SOURCES_FAILURE: 'FETCH_SOURCES_FAILURE',
  FETCH_SOURCES_SUCCESS: 'FETCH_SOURCES_SUCCESS',
};

// @TODO: use the method in actions/entities
export const fetchSources = () => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.FETCH_SOURCES_REQUEST,
  });
  let response;
  try {
    response = await api.get('sources');
  } catch (error) {
    return dispatch({
      type: actionTypes.FETCH_SOURCES_FAILURE,
      // Commented out because it is non serializable  thus not accepted by redux
      // error,
    });
  }
  return dispatch({
    type: actionTypes.FETCH_SOURCES_SUCCESS,
    data: response.data,
  });
};
