import { api } from 'actions/utils';

import { actionTypes as viewActionTypes } from './view';

export const actionTypes = {
  CREATE_PRODUCT_HIERARCHY_GROUPS_REQUEST:
    'CREATE_PRODUCT_HIERARCHY_GROUPS_REQUEST',
  CREATE_PRODUCT_HIERARCHY_GROUPS_SUCCESS:
    'CREATE_PRODUCT_HIERARCHY_GROUPS_SUCCESS',
  CREATE_PRODUCT_HIERARCHY_GROUPS_FAILURE:
    'CREATE_PRODUCT_HIERARCHY_GROUPS_FAILURE',

  UPDATE_PRODUCT_HIERARCHY_GROUPS_REQUEST:
    'UPDATE_PRODUCT_HIERARCHY_GROUPS_REQUEST',
  UPDATE_PRODUCT_HIERARCHY_GROUPS_SUCCESS:
    'UPDATE_PRODUCT_HIERARCHY_GROUPS_SUCCESS',
  UPDATE_PRODUCT_HIERARCHY_GROUPS_FAILURE:
    'UPDATE_PRODUCT_HIERARCHY_GROUPS_FAILURE',

  DELETE_PRODUCT_HIERARCHY_GROUPS_REQUEST:
    'DELETE_PRODUCT_HIERARCHY_GROUPS_REQUEST',
  DELETE_PRODUCT_HIERARCHY_GROUPS_SUCCESS:
    'DELETE_PRODUCT_HIERARCHY_GROUPS_SUCCESS',
  DELETE_PRODUCT_HIERARCHY_GROUPS_FAILURE:
    'DELETE_PRODUCT_HIERARCHY_GROUPS_FAILURE',

  FETCH_PRODUCT_HIERARCHY_GROUPS_REQUEST:
    'FETCH_PRODUCT_HIERARCHY_GROUPS_REQUEST',
  FETCH_PRODUCT_HIERARCHY_GROUPS_SUCCESS:
    'FETCH_PRODUCT_HIERARCHY_GROUPS_SUCCESS',
  FETCH_PRODUCT_HIERARCHY_GROUPS_FAILURE:
    'FETCH_PRODUCT_HIERARCHY_GROUPS_FAILURE',

  FETCH_ONE_PRODUCT_HIERARCHY_GROUPS_SUCCESS:
    'FETCH_ONE_PRODUCT_HIERARCHY_GROUPS_SUCCESS',
};

const getProductHierarchyGroupPayload = ({
  id = null,
  name,
  productHierarchies,
}) => {
  const payload = {
    name,
    items: productHierarchies.map((item) => ({ id: item.id })),
  };
  if (id) payload.id = id;
  return payload;
};

export const createProductHierarchyGroup =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.CREATE_PRODUCT_HIERARCHY_GROUPS_REQUEST });
    const apiPayload = getProductHierarchyGroupPayload(payload);
    let response;
    try {
      response = await api.post('product-hierarchy-groups', apiPayload);
    } catch (error) {
      return dispatch({
        type: actionTypes.CREATE_PRODUCT_HIERARCHY_GROUPS_FAILURE,
        // Commented out because it is non serializable  thus not accepted by redux
        // error: true,
      });
    }
    return dispatch({
      type: actionTypes.CREATE_PRODUCT_HIERARCHY_GROUPS_SUCCESS,
      productHierarchyGroup: response.data,
      filters: payload,
    });
  };

export const fetchProductHierarchyGroups = () => async (dispatch, getState) => {
  dispatch({ type: actionTypes.FETCH_PRODUCT_HIERARCHY_GROUPS_REQUEST });
  let response;
  try {
    response = await api.get('product-hierarchy-groups');
  } catch (error) {
    return dispatch({
      type: actionTypes.FETCH_PRODUCT_HIERARCHY_GROUPS_FAILURE,
      // Commented out because it is non serializable  thus not accepted by redux
      // error,
    });
  }
  return dispatch({
    type: actionTypes.FETCH_PRODUCT_HIERARCHY_GROUPS_SUCCESS,
    productHierarchyGroups: response.data,
  });
};

export const updateProductHierarchyGroup =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_PRODUCT_HIERARCHY_GROUPS_REQUEST });
    const apiPayload = getProductHierarchyGroupPayload(payload);
    let response;
    try {
      response = await api.put('product-hierarchy-groups', apiPayload);
    } catch (error) {
      return dispatch({
        type: actionTypes.UPDATE_PRODUCT_HIERARCHY_GROUPS_FAILURE,
        // Commented out because it is non serializable  thus not accepted by redux
        // error,
      });
    }
    // Reset related facets loaded data
    const group = getState().entities.productHierarchyGroups[payload.id];
    if (group) {
      dispatch({
        type: viewActionTypes.RESET_VIEW_FACET_AGGREGATES,
        viewFacetIds: group.related_view_facets.map(({ id }) => id),
      });
    }
    // Force update of product hierarchy groups (instead of waiting for next polling)
    dispatch(fetchProductHierarchyGroups());
    return dispatch({
      type: actionTypes.UPDATE_PRODUCT_HIERARCHY_GROUPS_SUCCESS,
      productHierarchyGroup: response.data,
      filters: payload,
    });
  };

export const maybeFetchProductHierarchyGroups =
  () => async (dispatch, getState) => {
    if (!getState().entities.loaded.includes('productHierarchyGroups')) {
      dispatch(fetchProductHierarchyGroups());
    }
  };

export const deleteProductHierarchyGroup =
  (productHierarchyGroupId) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.DELETE_PRODUCT_HIERARCHY_GROUPS_REQUEST });
    try {
      await api.delete(`product-hierarchy-groups/${productHierarchyGroupId}`);
    } catch (error) {
      return dispatch({
        type: actionTypes.DELETE_PRODUCT_HIERARCHY_GROUPS_FAILURE,
        // Commented out because it is non serializable  thus not accepted by redux
        // error,
      });
    }
    // Update the store with the new view facets
    // Removed for now, is it useful? Probably not as we remove the group reference
    // in the reducer anyway
    return dispatch({
      type: actionTypes.DELETE_PRODUCT_HIERARCHY_GROUPS_SUCCESS,
      productHierarchyGroupId,
    });
  };
